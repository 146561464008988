import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Comic Sans MS';
`;

export function Results({ name }: { name: string }) {
  return (
    <Container>
      <h2>Life is good innit!</h2>
      <p>
        Hello <strong>{name}</strong>, I hope you are doing well!
      </p>
    </Container>
  );
}
