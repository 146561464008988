import React, { useState } from 'react';
import { Loader } from './Loader';
import { registerUser } from '../api/endpoints/serviceApi';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export function InputForm({ onSubmit }: { onSubmit: (name: string) => void }) {
  const [success, setSuccess] = useState<string | null>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setName] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    onSubmit(username);
    registerUser({ username, password })
      .then((response: { message: string }) => {
        setLoading(false);
        setSuccess(response.message);
      })
      .catch(() => setError(true));
  };

  if (error) {
    return <div>ERROR OCCURED! PLEASE REFRECH PAGE</div>;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit} aria-disabled={!success}>
        <>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={username}
            onChange={handleNameChange}
          />
        </>
        <>
          <label htmlFor="name">Password:</label>
          <input
            type="passord"
            id="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </>
        <button type="submit">Submit</button>
      </Form>
      <h4>{success}</h4>
    </Container>
  );
}
