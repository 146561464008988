import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './Home';
import React from 'react';

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
    </Routes>
  );
}
