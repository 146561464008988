import React, { useState } from 'react';
import styled from 'styled-components';
import { InputForm } from '../Components/InputForm';
import { Results } from '../Components/Result';

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export default function Home() {
  const [name, setName] = useState<string>();

  return (
    <MainContainer>
      <InputForm onSubmit={setName} />
      {name && <Results name={name} />}
    </MainContainer>
  );
}
