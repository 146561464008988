import { serviceApiBaseUrl } from '../../utils/urls';

const SERVICE_GREETING_ENDPOINT = `${serviceApiBaseUrl()}/service/name`;
const REGISTER_ENDPOINT = `${serviceApiBaseUrl()}/service/register`;

const REGISTRATION_ERROR_MESSAGE = 'Error registering user';

export const fetchGreeting = () => {
  return fetch(SERVICE_GREETING_ENDPOINT);
};

export const registerUser = async (registrationData: {
  username: string;
  password: string;
}) => {
  try {
    const response = await fetch(REGISTER_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(registrationData),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('Success:', data);
    return data;
  } catch (error) {
    throw new Error(REGISTRATION_ERROR_MESSAGE);
  }
};
